$gray: #d2d2d2;

.footer-root {
    font-size: 14px;
    background-color: white;
    margin-top: 20px;
    padding: {
        top: 30px;
        bottom: 40px;
    }
    color: $gray;
    .company-name {
        margin-bottom: 4px;
        color: #333333;
        font: {
            weight: bolder;
            size: 18px;
        }
    }
    .company-information {
        margin-bottom: 20px;
        span {
            padding: 0 1em;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
    .links {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                display: inline-block;
                margin-right: 40px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    text-decoration: none;
                    color: $gray;
                }
            }
        }
    }
}