.chart-select-button-group-root {
    margin-bottom: 6px;

    .selector-group {
        // height: 42px;
        border: solid 1px #cccccc;
        padding: {
            left: 40px;
            right: 40px;
        }
    }
    .button-group-name {
        font-size: 12px;
        color: #bbbbbb;
        margin-left: -30px;
    }
}