.PrivateTabIndicator-colorSecondary-3 {
    background-color: #00005b !important;
}
button.Mui-selected {
    background-color: white;
    font-weight: bolder;
}
.jss3 {
    // active tab
    background-color: #00005b !important;
}