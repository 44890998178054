.chart-select-button-root {
    display: inline-block;
    margin: 7px 5px;
    border-radius: 1em;
    padding: 4px 12px;
    color: #312d49;
    font-size: 16px;

    &.depth-2 {
        font-size: 14px;
        margin: {
            top: 7px;
            bottom: 7px;
        }
    }

    &.active {
        background-color: #d91414;
        color: white;

        &:hover {
            background-color: #d91414;
            cursor: default;
            a span {
                color: white !important;
            }
        }

        .chart-select-link {
            text-decoration: none;
            color: white;
        }
    }

    &.highlight {
        a span {
            color: #ea1b27 !important;
            font-weight: bolder;

            &:hover {
                color: #ea1b27;
                cursor: default;
            }
        }
        &.active {
            a span {
                color: white !important;
                font-weight: normal;
            }
        }
    }

    &:hover a span {
        // background-color: rgba(217, 20, 20, 0.2);
        color: #ea1b27;
        cursor: pointer;
    }

    .chart-select-link {
        text-decoration: none;
        color: #312d49;
    }
}