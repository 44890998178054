$button-color: #00005b;
$danger-color: #d91414;
$gray: #9a9a9a;

.MuiDialogContent-root {
    text-align: center;
    color: #333333;
    padding: 60px 46px 65px 46px !important;
}

.modal-title {
    .modal-logo {
        width: 66px;
    }
    .modal-title {
        margin-top: 8px;
        font: {
            size: 36px;
            weight: normal;
        }
    }
}
.modal-description {
    font-size: 18px;
}
.modal-form {
    margin-top: 40px;
}
.modal-error {
    color: $danger-color;
    text-align: left;
    padding-left: 1em;
    margin-top: 10px;
    margin-bottom: 15px;
}
.modal-information {
    margin-bottom: 40px;
    text-align: left;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            &:before {
                content: "- ";
            }
        }
    }
}
.modal-action {
    .clickable {
        width: 172px;
        padding: 16px 28px;
        font-size: 16px;
        display: inline-block;
        box-shadow: none;
        border-radius: 32px;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        }
        margin-left: 30px;
        &:first-child {
            margin-left: 0;
        }
    }
    .filled-button {
        background-color: $button-color;
        color: white;
    }
    .outlined-button {
        border: $button-color 2px solid;
    }
}

.close-button {
    color: $gray;
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
    font: {
        size: 20px;
        weight: bolder;
    }
}