.trend-root {
    margin-top: 175px;
    text-align: center;
    h1 {
        margin-bottom: 10px;
        font: {
            weight: bolder;
            size: 52px;
        }
    }
    .description {
        margin-bottom: 60px;
        font-size: 21px;
    }
    .comming-soon-image {
        width: 440px;
    }
    .links {
        margin: {
            top: 60px;
            bottom: 100px;
        }
        a {
            text-decoration: none;
            color: #4aa7f6;
            font-size: 21px;
        }
    }
}