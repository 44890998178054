.header-root {
    padding: {
        top: 150px;
        bottom: 100px;
    };
    text-align: center;

    h1 {
        color: #312d49;
        margin: {
            top: 0;
            bottom: 30px;
        }
        font: {
            size: 77px;
        }

        sup svg {
            color: #979797;
            height: 20px !important;
        }
    }
    .description {
        color: #333333;
        font-size: 18px;
        margin-bottom: 50px;

        .alert {
            color: #f16c6c;
            font-size: 16px;
            margin-top: 30px;

            .alert-body {
                display: inline-block;
                background-color: #eaebef;
                width: 876px;
                padding: 18px 0;

                small {
                    display: block;
                    font-size: 10px;
                }
            }
        }
    }

    .actions {
        font-size: 18px;
        color: #333333;

        .update-time-group {
            display: inline-block;

            .update-time-info {
                display: block;
                position: absolute;
                font-size: 10px;
                color: #666666;
                padding: {
                    top: 5px;
                    left: 40px;
                }
            }
        }

        .clickable-item-box {
            display: inline-block;
        }
        .action-item {
            display: inline-block;
            background-color: white;
            padding: 13px 24px 10px 24px;
            // padding: 10px 18px;
            height: 32px;
            margin: 0 10px;

            &.clickable {
                box-shadow: none;
                cursor: pointer;
                padding: 10px 20px 12px 20px;
                &:hover {
                    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
                }
            }

            &.update-time {
                font-size: 24px;
                padding: 6px 25px 15px 25px;
            }

            .icon {
                vertical-align: middle;
                margin-right: 10px;
                height: 38px;
            }
            small {
                display: inline-block;
                margin-left: 3px;
                font-size: 10px;
                color: #312d49;
            }
        }
    }
}
