@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
.gnb-root{flex-grow:1}.gnb-root .title{flex-grow:1}.gnb-root .logo{color:red}.gnb-root .gnb{background-color:rgba(255,255,255,0.85) !important}.gnb-root .gnb .logo{width:116px;position:absolute;left:25px}.gnb-root .gnb .tabs{margin:0 auto}.gnb-root .gnb .tab{height:63px;color:#312d49;opacity:1 !important;font-size:16px;padding-top:5px}.gnb-root .comming-soon{margin-top:-25px}.gnb-root .comming-soon .imageBox{text-align:right}.gnb-root .comming-soon .imageBox .image{height:17px}.gnb-root .comming-soon .content{line-height:60% !important}

.PrivateTabIndicator-colorSecondary-3{background-color:#00005b !important}button.Mui-selected{background-color:white;font-weight:bolder}.jss3{background-color:#00005b !important}

.header-root{padding-top:150px;padding-bottom:100px;text-align:center}.header-root h1{color:#312d49;margin-top:0;margin-bottom:30px;font-size:77px}.header-root h1 sup svg{color:#979797;height:20px !important}.header-root .description{color:#333333;font-size:18px;margin-bottom:50px}.header-root .description .alert{color:#f16c6c;font-size:16px;margin-top:30px}.header-root .description .alert .alert-body{display:inline-block;background-color:#eaebef;width:876px;padding:18px 0}.header-root .description .alert .alert-body small{display:block;font-size:10px}.header-root .actions{font-size:18px;color:#333333}.header-root .actions .update-time-group{display:inline-block}.header-root .actions .update-time-group .update-time-info{display:block;position:absolute;font-size:10px;color:#666666;padding-top:5px;padding-left:40px}.header-root .actions .clickable-item-box{display:inline-block}.header-root .actions .action-item{display:inline-block;background-color:white;padding:13px 24px 10px 24px;height:32px;margin:0 10px}.header-root .actions .action-item.clickable{box-shadow:none;cursor:pointer;padding:10px 20px 12px 20px}.header-root .actions .action-item.clickable:hover{box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)}.header-root .actions .action-item.update-time{font-size:24px;padding:6px 25px 15px 25px}.header-root .actions .action-item .icon{vertical-align:middle;margin-right:10px;height:38px}.header-root .actions .action-item small{display:inline-block;margin-left:3px;font-size:10px;color:#312d49}


.chart-select-button-root{display:inline-block;margin:7px 5px;border-radius:1em;padding:4px 12px;color:#312d49;font-size:16px}.chart-select-button-root.depth-2{font-size:14px;margin-top:7px;margin-bottom:7px}.chart-select-button-root.active{background-color:#d91414;color:white}.chart-select-button-root.active:hover{background-color:#d91414;cursor:default}.chart-select-button-root.active:hover a span{color:white !important}.chart-select-button-root.active .chart-select-link{text-decoration:none;color:white}.chart-select-button-root.highlight a span{color:#ea1b27 !important;font-weight:bolder}.chart-select-button-root.highlight a span:hover{color:#ea1b27;cursor:default}.chart-select-button-root.highlight.active a span{color:white !important;font-weight:normal}.chart-select-button-root:hover a span{color:#ea1b27;cursor:pointer}.chart-select-button-root .chart-select-link{text-decoration:none;color:#312d49}

.chart-select-button-group-root{margin-bottom:6px}.chart-select-button-group-root .selector-group{border:solid 1px #cccccc;padding-left:40px;padding-right:40px}.chart-select-button-group-root .button-group-name{font-size:12px;color:#bbbbbb;margin-left:-30px}

.chart-content-root{margin-top:4px}.chart-content-root .chart-channel-info{text-align:right;font-size:10px;color:#666666;margin-top:20px;margin-bottom:11px}.chart-content-root thead th{background-color:#f1f2f3;color:#999999;font-size:14px;padding-top:14px;padding-bottom:14px}.chart-content-root td{padding-top:13px;padding-bottom:13px}.chart-content-root .product-image{width:60px;vertical-align:middle;margin-right:20px;margin-top:-25px}.chart-content-root .product-name-group{display:inline-block;font-size:18px}.chart-content-root .product-name-group .channel-image{height:25px;display:block}.chart-content-root .noproduct{text-align:center;padding:100px 0;font-size:18px}.chart-content-root .noproduct .noproduct-img{width:300px;margin-bottom:11px}.chart-content-root .noproduct small{font-size:10px}

.MuiDialogContent-root{text-align:center;color:#333333;padding:60px 46px 65px 46px !important}.modal-title .modal-logo{width:66px}.modal-title .modal-title{margin-top:8px;font-size:36px;font-weight:normal}.modal-description{font-size:18px}.modal-form{margin-top:40px}.modal-error{color:#d91414;text-align:left;padding-left:1em;margin-top:10px;margin-bottom:15px}.modal-information{margin-bottom:40px;text-align:left}.modal-information ul{margin:0;padding:0;list-style-type:none}.modal-information ul li:before{content:"- "}.modal-action .clickable{width:172px;padding:16px 28px;font-size:16px;display:inline-block;box-shadow:none;border-radius:32px;cursor:pointer;margin-left:30px}.modal-action .clickable:hover{box-shadow:0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)}.modal-action .clickable:first-child{margin-left:0}.modal-action .filled-button{background-color:#00005b;color:white}.modal-action .outlined-button{border:#00005b 2px solid}.close-button{color:#9a9a9a;position:absolute;top:20px;right:30px;cursor:pointer;font-size:20px;font-weight:bolder}

.footer-root{font-size:14px;background-color:white;margin-top:20px;padding-top:30px;padding-bottom:40px;color:#d2d2d2}.footer-root .company-name{margin-bottom:4px;color:#333333;font-weight:bolder;font-size:18px}.footer-root .company-information{margin-bottom:20px}.footer-root .company-information span{padding:0 1em}.footer-root .company-information span:first-child{padding-left:0}.footer-root .company-information span:last-child{padding-right:0}.footer-root .links ul{margin:0;padding:0;list-style-type:none}.footer-root .links ul li{display:inline-block;margin-right:40px}.footer-root .links ul li:last-child{margin-right:0}.footer-root .links ul li a{text-decoration:none;color:#d2d2d2}

.trend-root{margin-top:175px;text-align:center}.trend-root h1{margin-bottom:10px;font-weight:bolder;font-size:52px}.trend-root .description{margin-bottom:60px;font-size:21px}.trend-root .comming-soon-image{width:440px}.trend-root .links{margin-top:60px;margin-bottom:100px}.trend-root .links a{text-decoration:none;color:#4aa7f6;font-size:21px}

* { font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif' !important; }

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

