.chart-content-root {
    margin-top: 4px;

    .chart-channel-info {
        text-align: right;
        font-size: 10px;
        color: #666666;
        margin: {
            top: 20px;
            bottom: 11px;
        }
    }

    thead {
        th {
            background-color: #f1f2f3;
            color: #999999;
            font-size: 14px;
            padding: {
                top: 14px;
                bottom: 14px;
            }
        }
    }
    td {
        padding: {
            top: 13px;
            bottom: 13px;
        }
    }

    .product-image {
        width: 60px;
        vertical-align: middle;
        margin-right: 20px;
        margin-top: -25px;
    }
    .product-name-group {
        display: inline-block;
        font-size: 18px;
        .channel-image {
            height: 25px;
            display: block;
        }
    }

    .noproduct {
        text-align: center;
        padding: 100px 0;
        font-size: 18px;
        .noproduct-img {
            width: 300px;
            margin-bottom: 11px;
        }
        small {
            font-size: 10px;
        }
    }
}