.gnb-root {
    flex-grow: 1;

    .title {
        flex-grow: 1;
    }

    .logo {
        color: red;
    }

    .gnb {
        background-color: rgba(255, 255, 255, 0.85) !important;

        .logo{
            width: 116px;
            position: absolute;
            left: 25px;
        }
        .tabs{
            margin: 0 auto;
        }
        .tab{
            height: 63px;
            color: #312d49;
            opacity: 1 !important;
            font-size: 16px;
            padding-top: 5px;
        }
    }

    .comming-soon{
        margin-top: -25px;

        .imageBox {
            text-align: right;

            .image {
                height: 17px;
            }
        }

        .content{
            line-height: 60% !important;
        }
    }
}